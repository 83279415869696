import React from "react";
import { PageHeaderWrapper } from "./styled";
import { PageContent } from "../../../assets/styles/pageCommon";
import InfoCircles from "../InfoCircles";
import { connect } from "react-redux";

const PageHeader = (props) => {
	const {
		layout = "light",
		sticky = false,
		title = null,
		text = null,
		loggedIn,
		usuario,
		favoritos,
		alertas,
		headerHide,
		children,
	} = props;

	const formatearChecksCount = (total) => {
		let formatted = "";
		if (total > 999) {
			formatted = `${Math.round((total / 1000) * 10) / 10}k`;
		} else if (total > 999999) {
			// por si tiene un millon de checks, ah re
			formatted = `${Math.round((total / 1000000) * 10) / 10}m`;
		} else {
			return total.toString();
		}
		return formatted;
	};

	return (
		<PageHeaderWrapper
			layout={layout}
			sticky={sticky}
			headerHide={headerHide}
		>
			<PageContent width="1050px">
				<div className="pageHeaderInfo">
					{title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
					{text && <p>{text}</p>}
				</div>

				{loggedIn && !children && (
					<InfoCircles
						items={[
							{
								label: "Reviews",
								value: formatearChecksCount(usuario.checks_count),
							},
							{
								label:
									favoritos &&
									favoritos.length &&
									favoritos.length === 1
										? "Favorito"
										: "Favoritos",
								value:
									favoritos && favoritos.length ? favoritos.length : 0,
							},
							{
								label:
									alertas && alertas.length && alertas.length === 1
										? "Alerta"
										: "Alertas",
								value: alertas && alertas.length ? alertas.length : 0,
							},
						]}
					/>
				)}

				{children}
			</PageContent>
		</PageHeaderWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedIn: state.auth.loggedIn,
		usuario: state.auth.usuario,
		favoritos: state.favoritos.items,
		alertas: state.alertas.items,
		headerHide: state.app.global.headerHide,
	};
};

export default connect(mapStateToProps, null)(PageHeader);
