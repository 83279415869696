import styled from "styled-components";
import { colors } from "../../../assets/styles/vars";

export const VideoPlayerWrapper = styled.div`
	position: relative;
	width: 100%;
	min-height: 300px;
	&:before {
		content: "";
		display: block;
		//padding-bottom: 56.25%;
		padding-bottom: 62%;
	}
	.imagePreview {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		overflow: hidden;
		cursor: pointer;
		span.icon {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 50px;
			height: 50px;
			z-index: 10;
			path {
				&:nth-child(1) {
					fill: ${colors.main};
				}
				&:nth-child(2) {
					fill: ${colors.black00};
				}
			}
		}
		.overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 5;
			background-color: rgba(0, 0, 0, 0.4);
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: relative;
			z-index: 0;
		}
	}
	.playerWrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
	}
	.loadingWrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
`;
