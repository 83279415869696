import React from "react";
import { InfoCirclesWrapper, InfoCirclesItem } from "./styled";

const InfoCircles = ({ layout, items }) => {
	if (!items) return false;
	return (
		<InfoCirclesWrapper>
			{items
				?.filter((item) => item.value !== "")
				.map((item, index) => {
					return (
						<InfoCirclesItem
							layout={layout}
							active={item.active === undefined ? true : item.active}
							key={index}
						>
							<h5>{item.value}</h5>
							<small>{item.label}</small>
						</InfoCirclesItem>
					);
				})}
		</InfoCirclesWrapper>
	);
};

export default InfoCircles;
