import styled, { css } from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";
import { PageContent } from "../../../assets/styles/pageCommon";
import { InfoCirclesWrapper } from "../InfoCircles/styled";

export const PageHeaderWrapper = styled.section`
	background-color: ${(props) =>
		props.layout === "dark" ? colors.black00 : colors.white};
	padding: 35px 0;
	${(props) =>
		props.sticky &&
		css`
			position: sticky;
			z-index: 100;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
			transition: top 0.3s ease;
			top: ${props.headerHide ? 0 : 132}px;
			@media ${mediaQueryMax.lg} {
				top: ${props.headerHide ? 0 : 70}px;
			}
		`};
	@media ${mediaQueryMax.lg2} {
		padding: 20px 0;
	}
	${PageContent} {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		gap: 25px;
		padding-top: 0;
		padding-bottom: 0;
	}
	p {
		color: ${colors.gray};
		font-size: 15px;
		line-height: 1.14;
	}
	.pageHeaderInfo {
		flex: 1;
		${(props) =>
			props.sticky &&
			css`
				@media ${mediaQueryMax.md} {
					display: none;
				}
			`};
		h3 {
			color: ${(props) =>
				props.layout === "dark" ? colors.white : colors.black00};
			margin: 0;
			@media ${mediaQueryMax.xs2} {
				font-size: 18px;
			}
		}
		span.emoji {
			font-family: "NotoColorEmoji";
		}
		p {
			margin: 5px 0 0;
		}
	}
	${InfoCirclesWrapper} {
		flex: auto;
		justify-content: flex-end;
		h5 {
			color: ${(props) =>
				props.layout === "dark" ? colors.main : colors.black00};
			border-color: ${(props) =>
				props.layout === "dark" ? colors.main : colors.black00};
		}
		small {
			color: ${(props) =>
				props.layout === "dark" ? colors.main : colors.black00};
		}
	}
	.pageHeaderContent {
		//flex: 1;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;
		@media ${mediaQueryMax.md} {
			width: 100%;
			justify-content: space-between;
		}
		.pageHeaderContentText {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
			@media ${mediaQueryMax.md} {
				flex-direction: column;
				align-items: baseline;
				gap: 0;
			}
			p {
				margin: 0;
				@media ${mediaQueryMax.md} {
					order: 1;
				}
			}

			h5 {
				margin: 0;
				@media ${mediaQueryMax.md} {
					order: 0;
				}
			}
		}
		a,
		button {
			p {
				text-decoration: none;
			}
		}
	}
`;
