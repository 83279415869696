import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { Link } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { appModal, appRedirect } from "../redux/app/actions";
import {
	PageContentWrapper,
	PageContent,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import PageHeader from "../components/Common/PageHeader";
import { colors } from "../assets/styles/vars";
import {
	Breadcrumb,
	CustomButton,
	StyledSliderButton,
} from "../assets/styles/globalCommon";
import VideoPlayer from "../components/Common/VideoPlayer";
import {
	ListItem,
	ModulesListWrapper,
} from "../components/Common/ModulesList/styled";
import { ReactSVG } from "react-svg";
import BannerTwoCol from "../components/Common/BannerTwoCol";
import InfoCircles from "../components/Common/InfoCircles";

const CursosPage = ({
	authResolved,
	loggedIn,
	isPremium,
	appModal,
	appRedirect,
}) => {
	const videoPlayerRef = useRef();
	const certificadoRef = useRef();
	const [currentModule, setCurrentModule] = useState(0);
	const [moduleOneState, setModuleOneState] = useState("canPlay");
	const moduleOneRef = useRef();
	const [moduleTwoState, setModuleTwoState] = useState("disabled");
	const moduleTwoRef = useRef();
	const [moduleThreeState, setModuleThreeState] = useState("disabled");
	const moduleThreeRef = useRef();
	const [seekVideoTime, setSeekVideoTime] = useState(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [videoDisabled, setVideoDisabled] = useState(false);

	// useEffect(() => {
	// 	if (authResolved && !loggedIn) appRedirect({ to: "/" });
	// }, [authResolved, loggedIn]);

	const onVideoEnd = () => {
		// console.log("onVideoEnd");
		// SE VIO EL 1
		if (currentModule === 0) {
			if (moduleOneState !== "completed") setModuleOneState("videoSeen");
			if (moduleTwoState !== "canPlay") setVideoDisabled(true);
			moduleOneRef?.current?.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}
		// SE VIO EL 2
		if (currentModule === 1) {
			if (moduleTwoState !== "completed") setModuleTwoState("videoSeen");
			if (moduleThreeState !== "canPlay") setVideoDisabled(true);
			moduleTwoRef?.current?.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}
		// SE VIO EL 3
		if (currentModule === 2) {
			setModuleThreeState("videoSeen");
			moduleThreeRef?.current?.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}
	};

	const completeHandle = (index) => {
		// console.log("completeHandle");
		setVideoDisabled(false);
		if (index === 0) {
			if (!isPremium) {
				appModal("popuppremium", { campaign: "signup" });
			} else {
				setModuleOneState("completed");
				setModuleTwoState("canPlay");
				setCurrentModule(1);
				videoPlayerRef?.current?.scrollIntoView({
					block: "center",
					behavior: "smooth",
				});
			}
		}
		if (index === 1) {
			setModuleTwoState("completed");
			setModuleThreeState("canPlay");
			setCurrentModule(2);
			videoPlayerRef?.current?.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}
		if (index === 2) {
			setModuleThreeState("completed");
			certificadoRef?.current?.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}
	};

	const videoModulesCircles = [
		{
			label: "Módulo",
			value: "1",
			active: currentModule >= 0,
		},
		{
			label: "Módulo",
			value: "2",
			active: currentModule >= 1,
		},
		{
			label: "Módulo",
			value: "3",
			active: currentModule >= 2,
		},
	];

	const videoModulesItems = [
		{
			icon: "/img/icons/icon-check.svg",
			title: "Modulo 1",
			text: "Este es el módulo de bienvenida, te contaremos  que puedes hacer con Fresh Snow y como primer objetivo, aprender a gestionar e interpretar las alertas y ordenar tus favoritos, son un factor clave para que te anticipes a las nevadas y organices tus viajes.",
			videoUrl: "https://vimeo.com/786923753",
			imgPreview: "/courses/courses-video-preview.png",
		},
		{
			icon: "/img/icons/icon-check.svg",
			title: "Modulo 2",
			text: "Conoce el panel de clima al completo, te explicaremos que es que nuestro forecast. En esta unidad también te explicaremos la importancia de las reviews de usuarios y como crear una para la comunidad.",
			videoUrl: "https://vimeo.com/786923792",
			imgPreview: "/courses/courses-video-preview.png",
		},
		{
			icon: "/img/icons/icon-check.svg",
			title: "Modulo 3",
			text: "Una vez comprendas la información que compone nuestro forecast, vamos a explicarte como realizar análisis combinando datos de clima, no siempre lo que se ve es lo que parece. Aprende a mirar partes de nieve como un pro y sácale más partido a tu día de nieve.",
			videoUrl: "https://vimeo.com/786955018",
			imgPreview: "/courses/courses-video-preview.png",
		},
	];

	const renderIcon = (thisModule, index) => {
		const iconSrc =
			thisModule === "disabled"
				? "/img/icons/icon-lock.svg"
				: videoModulesItems[index]?.icon;
		let iconClass = "icon list-icon";
		if (thisModule === "completed") {
			iconClass += " secondary";
		} else if (thisModule !== "disabled") {
			iconClass += " gray40";
		}
		return <ReactSVG src={iconSrc} wrapper="span" className={iconClass} />;
	};

	const getBtnCompleteDisabled = () => {
		return (
			<CustomButton className="secondLevel disabled withIcon">
				<p>Completar</p>
				<ReactSVG
					src="/img/icons/icon-arrow-right.svg"
					wrapper="span"
					className="icon"
				/>
			</CustomButton>
		);
	};

	const getBtnComplete = (index) => {
		return (
			<CustomButton
				onClick={() => completeHandle(index)}
				className="withIcon"
			>
				<p>Completar</p>
				<ReactSVG
					src="/img/icons/icon-arrow-right.svg"
					wrapper="span"
					className="icon"
				/>
			</CustomButton>
		);
	};

	const getBtnVer = (index) => {
		return (
			<CustomButton
				onClick={() => {
					setCurrentModule(index);
					setSeekVideoTime(0);
					videoPlayerRef?.current?.scrollIntoView({
						block: "center",
						behavior: "smooth",
					});
				}}
				className="ghost withUnderline"
			>
				<p>Volver a ver</p>
			</CustomButton>
		);
	};

	const renderButtons = (moduleState, index) => {
		// console.log("renderButtons", moduleState, index);
		switch (moduleState) {
			case "disabled":
				return getBtnCompleteDisabled();
			case "canPlay":
				return (
					<>
						{getBtnCompleteDisabled()} {getBtnVer(index)}
					</>
				);
			case "videoSeen":
				return (
					<>
						{getBtnComplete(index)} {getBtnVer(index)}
					</>
				);
			case "completed":
				return getBtnVer(index);
			default:
				return null;
		}
	};

	return (
		<Layout>
			<Seo title="Cursos" />
			<section>
				<PageHeader title="Cursos">
					<InfoCircles items={videoModulesCircles} />
				</PageHeader>

				<PageContentWrapper bgColor={colors.grayF2}>
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<Breadcrumb>
									<Link to="/account">Mi cuenta</Link>
									<a>></a>
									<a>Cursos</a>
								</Breadcrumb>
								<h3>Bienvenid@ a nuestro curso online</h3>
								<p>
									Esperamos que al terminar nuestro curso puedas
									aprender a tomar las mejores decisiones. Sabemos que
									esquiar cuesta dinero, en Fresh Snow queremos que lo
									hagas en los mejores días para poder aprovechar al
									máximo tu inversión.
									<br />
									<br />
									¡Comencemos!
								</p>
							</div>

							<div className="pageCol fullCol" ref={videoPlayerRef}>
								<VideoPlayer
									videoUrl={videoModulesItems[currentModule]?.videoUrl}
									imgPreview={
										videoModulesItems[currentModule]?.imgPreview
									}
									isPlaying={isPlaying}
									setIsPlaying={setIsPlaying}
									disabled={videoDisabled}
									seekTime={seekVideoTime}
									setSeekVideoTime={setSeekVideoTime}
									onEndAction={onVideoEnd}
								/>
							</div>

							<div className="pageCol fullCol">
								<ModulesListWrapper>
									<div className="listRow">
										{videoModulesItems?.map((item, index) => {
											const getModuleIcon = () => {
												if (index === 0) {
													return renderIcon(moduleOneState, index);
												} else if (index === 1) {
													return renderIcon(moduleTwoState, index);
												} else if (index === 2) {
													return renderIcon(
														moduleThreeState,
														index,
													);
												}
											};
											const getModuleState = () => {
												if (index === 0) {
													return renderButtons(
														moduleOneState,
														index,
													);
												} else if (index === 1) {
													return renderButtons(
														moduleTwoState,
														index,
													);
												} else if (index === 2) {
													return renderButtons(
														moduleThreeState,
														index,
													);
												}
											};
											const getModuleRef = () => {
												switch (index) {
													case 0:
														return moduleOneRef;
													case 1:
														return moduleTwoRef;
													case 2:
														return moduleThreeRef;
													default:
														return null;
												}
											};

											return (
												<ListItem ref={getModuleRef()} key={index}>
													<div className="inner">
														{getModuleIcon()}
														<h4>{item?.title}</h4>
														<p
															dangerouslySetInnerHTML={{
																__html: item?.text,
															}}
														/>
														{getModuleState()}
													</div>
												</ListItem>
											);
										})}
									</div>
								</ModulesListWrapper>
							</div>

							<div className="pageCol fullCol" ref={certificadoRef}>
								<BannerTwoCol
									direction="right"
									layout="light"
									bgColor="transparent"
									size="small"
									img="/courses/certificado-img.jpg"
									title="Realizar exámen y obtener certificado"
									text="Si llegas a este punto seguro ya te sientes un expert@ del tema. es hora de demostrarlo para que puedas ganar tu merecido certificado de lectura de partes. ¡Vamos a ello!"
									buttonDisabled={moduleThreeState !== "completed"}
								>
									<br />
									<StyledSliderButton
										className={`${
											moduleThreeState !== "completed" &&
											"disabled secondLevel"
										} withIcon`}
										buttonProps={{
											disabled: moduleThreeState !== "completed",
										}}
										id="cl7hwitJ"
										size="75"
									>
										<p>Comenzar</p>
										<ReactSVG
											src="/img/icons/icon-arrow-right.svg"
											wrapper="span"
											className="icon"
										/>
									</StyledSliderButton>
								</BannerTwoCol>
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>

				<BannerTwoCol
					direction="right"
					img="/img/discord.png"
					title="¿Tienes alguna duda?"
					text="Te invitamos a particiar de ¡nuestro Discord! Si leiste bien, Fresh Snow tiene un canal de Discord para la comunidad de esquiadores.<br/><br/>Allí podrás resolver dudas, organizar viajes, hablar con expertos o aconsejar a principiantes, en definitiva, hacer amigos y formar parte del crecimiento de una comunidad de esquiadores en constante evolución."
					buttonUrl="https://discord.gg/sf8yKSbN"
					buttonText="Únete a discord"
					buttonIcon="/img/icons/icon-discord.svg"
				/>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { items, deleting, deletingSuccess, deletingError } = state.favoritos;
	return {
		items,
		deleting,
		deletingSuccess,
		deletingError,
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		isPremium: state.auth.usuario?.premium,
		// usuario: state.auth.usuario,
	};
};

const mapDispatchToProps = {
	appModal,
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(CursosPage);
