import React, { useEffect, useState, useRef } from "react";
import { VideoPlayerWrapper } from "./styled";
import { ReactSVG } from "react-svg";
import ReactPlayer from "react-player";
import { CSSTransition } from "react-transition-group";
import ReactLoading from "react-loading";

const VideoPlayer = (props) => {
	const {
		videoUrl,
		imgPreview,
		seekTime = null,
		setSeekVideoTime,
		disabled = false,
		onEndAction = null,
	} = props;
	const videoPlayerRef = useRef();
	const [internalPlayer, setInternalPlayer] = useState(null); //  PARA GUARDAR LA INSTANCIO DEL PLAYER IFRAME
	const [playing, setPlaying] = useState(false); // ESTADO PARA SWAP DEL PREVIEW

	const previewHandle = () => {
		if (!disabled) {
			setPlaying(true);
		}
	};

	useEffect(() => {
		setInternalPlayer(videoPlayerRef?.current?.getInternalPlayer());
	}, []);

	useEffect(() => {
		setPlaying(false);
	}, [videoUrl, imgPreview]);

	useEffect(() => {
		// console.log("seekTime useEffect", seekTime);
		if (seekTime !== null) {
			//SETEAMOS EL TIEMPO
			videoPlayerRef?.current?.seekTo(seekTime, "seconds");
			// RESETEAMOS SEEK
			setSeekVideoTime(null);
			// ASEGURAMOS EL PLAY
			setPlaying(true);
			internalPlayer?.play();
		}
	}, [seekTime]);

	return (
		<>
			{videoUrl && (
				<VideoPlayerWrapper>
					{imgPreview && (
						<CSSTransition
							in={!playing}
							timeout={300}
							classNames="fadeAnim"
							unmountOnExit
						>
							<div
								className="imagePreview"
								onClick={previewHandle}
								onKeyDown={null}
								role="button"
								tabIndex={0}
								aria-label="image-preview"
							>
								<ReactSVG
									src="/img/icons/icon-play.svg"
									wrapper="span"
									className="icon"
								/>
								<div className="overlay"></div>
								<img src={imgPreview} alt="" />
							</div>
						</CSSTransition>
					)}
					<div className="playerWrapper">
						<ReactPlayer
							ref={videoPlayerRef}
							className="react-player"
							width="100%"
							height="100%"
							playing={playing}
							playsinline={true}
							controls={true}
							url={videoUrl}
							onPlay={() => {}}
							onPause={() => {}}
							onEnded={onEndAction}
						/>
					</div>
					<div className="loadingWrapper">
						<ReactLoading
							type="spokes"
							color="rgb(0, 255, 252)"
							height={"32px"}
							width={"32px"}
						/>
					</div>
				</VideoPlayerWrapper>
			)}
		</>
	);
};

export default VideoPlayer;
