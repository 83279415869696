import styled, { css } from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

const bgColor = (bgColor, layout) => {
	if (bgColor !== "") {
		return css`
			background-color: transparent;
		`;
	} else {
		return css`
			background-color: ${layout === "dark" ? colors.black10 : colors.white};
		`;
	}
};

export const BannerTwoColWrapper = styled.section`
	position: relative;
	overflow: hidden;
	max-height: ${(props) => (props.size === "big" ? 650 : 300)}px;
	&:before {
		content: "";
		display: block;
		padding-bottom: 45%;
	}
	@media ${mediaQueryMax.lg} {
		max-height: none;
		&:before {
			content: none;
			display: none;
		}
	}
	.colWrapper {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: ${(props) => (props.size === "big" ? 0 : "25px")};
		${(props) => bgColor(props.bgColor, props.layout)};
		@media ${mediaQueryMax.lg} {
			position: relative;
			left: auto;
			top: auto;
			height: auto;
		}
		.col {
			height: 100%;
			flex: 1;
			max-width: 50%;
			@media ${mediaQueryMax.lg} {
				flex: 0 0 100%;
				max-width: 100%;
			}
			&.colImg {
				order: ${(props) => (props.direction === "left" ? 0 : 1)};
				@media ${mediaQueryMax.lg} {
					order: 0;
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&.colInfo {
				order: ${(props) => (props.direction === "left" ? 1 : 0)};
				height: auto;
				max-height: 100%;
				box-sizing: border-box;
				@media ${mediaQueryMax.lg} {
					order: 1;
				}
				${(props) =>
					props.size === "big" &&
					css`
						padding: 0 6%;
						@media ${mediaQueryMax.lg} {
							padding: 50px 35px;
						}
						@media ${mediaQueryMax.md} {
							padding: 50px 25px;
						}
					`}
				h3 {
					color: ${(props) =>
						props.layout === "dark" ? colors.white : colors.black00};
					margin-bottom: 18px;
				}
				p {
					color: ${(props) =>
						props.layout === "dark" ? colors.white : colors.black00};
				}
			}
		}
	}
`;
