import React from "react";
import { BannerTwoColWrapper } from "./styled";
import { CustomButton, CustomLink } from "../../../assets/styles/globalCommon";
import { ReactSVG } from "react-svg";

const BannerTwoCol = (props) => {
	const {
		direction = "left",
		layout = "dark",
		bgColor = "",
		size = "big",
		img,
		title,
		text,
		buttonUrl,
		buttonAction,
		buttonText,
		buttonIcon,
		buttonDisabled = false,
		children,
	} = props;

	const buttonClass = `${buttonDisabled && "disabled secondLevel"} withIcon`;

	return (
		<BannerTwoColWrapper
			direction={direction}
			layout={layout}
			bgColor={bgColor}
			size={size}
		>
			<div className="colWrapper">
				<div className="col colImg">
					<img src={img} alt={title} />
				</div>
				<div className="col colInfo">
					<h3>{title}</h3>
					<p dangerouslySetInnerHTML={{ __html: text }} />

					{buttonText && (buttonUrl || buttonAction) && (
						<>
							<br />
							{buttonUrl ? (
								<CustomLink
									to={buttonDisabled ? "" : buttonUrl}
									target="_blank"
									className={buttonClass}
								>
									<p>{buttonText}</p>
									<ReactSVG
										src={buttonIcon}
										wrapper="span"
										className="icon"
									/>
								</CustomLink>
							) : (
								<CustomButton
									onClick={!buttonDisabled ? buttonAction : undefined}
									className={buttonClass}
								>
									<p>{buttonText}</p>
									<ReactSVG
										src={buttonIcon}
										wrapper="span"
										className="icon"
									/>
								</CustomButton>
							)}
						</>
					)}

					{children && children}
				</div>
			</div>
		</BannerTwoColWrapper>
	);
};

export default BannerTwoCol;
