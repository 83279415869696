import styled, { css } from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";

export const InfoCirclesWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 10px;
	@media ${mediaQueryMax.sm} {
		gap: 14px;
	}
`;

const colorStyles = (props) => {
	const { layout, active } = props;
	if (layout === "dark") {
		return css`
			color: ${() => (!active ? colors.light20 : colors.white)};
			border-color: ${() => (!active ? colors.light20 : colors.white)};
		`;
	} else {
		return css`
			color: ${() => (!active ? colors.gray40 : colors.black00)};
			border-color: ${() => (!active ? colors.gray40 : colors.black13)};
		`;
	}
};

export const InfoCirclesItem = styled.div`
	flex: 0 0 76px;
	max-width: 76px;
	h5 {
		font-weight: 600;
		font-size: 20px;
		line-height: 1;
		text-align: center;
		border-width: 4px;
		border-style: solid;
		${(props) => colorStyles(props)};
		overflow: hidden;
		border-radius: 50%;
		width: 48px;
		height: 48px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin: 0 auto 10px;
	}
	small {
		display: block;
		font-size: 12px;
		line-height: 14px;
		${(props) => colorStyles(props)};
		text-transform: uppercase;
		text-align: center;
	}
	@media ${mediaQueryMax.sm} {
		flex: 0 0 auto;
		max-width: none;
		h5 {
			font-size: 16px;
			border-width: 2px;
			width: 38px;
			height: 38px;
		}
		small {
			font-size: 10px;
		}
	}
`;
